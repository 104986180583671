<template>
  <div>
    <!-- uploadC9A809AE-5B68-4F14-9DF8-147533D9D03B.swf -->
    <!-- pic4C6213A4CB2F939400E1EFA6F5119012.jpg -->
    <img class="img-large rounded" src="">
    <p>
      Brickkeeper needs help sowing the seeds of inspiration! Get out your gardening gloves and unleash your imagination to overcome the <router-link :to="{ name: 'creation-lab-list', params: {challengeId: 'EC8325C2-EA1E-4C79-81E6-75C6EEF6FDBA'} }">newest creative challenge</router-link>!
    </p>
    <p>
      Dream up a new type of plant to inhabit LEGO Universe!
    </p>
  </div>
</template>
